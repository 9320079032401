import styled from "styled-components"
import Text from "../../elements/text/text"

export const ParagraphText = styled(Text)`
  margin-bottom: 1rem;
  display: block;
`

export const TextSectionWrapper = styled.div`
  max-width: 750px;
  padding: 2rem 0;
`
