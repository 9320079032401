import React from "react"
import styled from "styled-components"
import { ParagraphText, TextSectionWrapper } from "../common-elements"
import ButtonPrimary from "../../elements/buttons/button-primary"

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${p => p.theme.bgColorDark};
  position: relative;
  overflow: hidden;
`
const StyledLinkWrapper = styled.a`
  margin-top: 2em;
  z-index: 100;
`

const StyledTextSectionWrapper = styled(TextSectionWrapper)`
  margin: 0 2em;
  @media (max-width: 1430px) {
    max-width: 545px;
  }
`

const BodyExternalLink = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.fontColorWhite};
  &:hover {
    color: ${({ theme }) => theme.fontColorWhiteReduced};
  }
`

export default ({ matches }) => {
  return (
    <Wrapper>
      <StyledLinkWrapper
        href={
          matches.normal
            ? "https://data.permianmap.org/pages/flaring"
            : "https://data.permianmap.org/pages/mobile-flaring"
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <ButtonPrimary>EXPLORE THE DATA</ButtonPrimary>
      </StyledLinkWrapper>
      <StyledTextSectionWrapper>
        <ParagraphText>
          The wasteful practice of burning away gas has long been a problem in
          the oilfield. Data shows that companies in the Permian Basin burned
          away roughly 1 trillion cubic feet of gas since 2013.
        </ParagraphText>
        <ParagraphText>
          While this waste problem has been well documented, much less has been
          known about methane emissions from flares.
        </ParagraphText>
        <ParagraphText>
          EDF has now conducted four surveys of over a thousand flare stacks
          across the basin, from February to November 2020, which reveal methane
          emissions from flaring are a significant and widespread problem.
        </ParagraphText>
        <ParagraphText>
          In each survey, we found that 10% of flares were either unlit—venting
          completely uncombusted methane directly to the atmosphere—or burning
          only part of the gas they were releasing.
        </ParagraphText>
        <ParagraphText>
          Although there was a large decrease in the volume of gas flared in
          late spring, due to the drop in production, there was no change in
          observed flare performance. We estimate at least 7% of Permian gas
          sent to flares is escaping directly into the atmosphere. This means
          companies are emitting 3.5 times more methane than what EPA assumes.
        </ParagraphText>
        <ParagraphText weight="bold" uppercase>
          At this rate, flaring can now be considered one of the largest sources
          of methane emissions in the Permian basin.
        </ParagraphText>
        <ParagraphText>
          Our surveys indicate that in order to reduce emissions across the
          basin, flaring must be reduced to the lowest levels possible.{" "}
          <BodyExternalLink
            target="_blank"
            rel="noopener noreferrer"
            href="http://blogs.edf.org/energyexchange/files/2021/05/Updated-VIIRS-trends.pdf"
          >
            With flaring volumes starting to increase again
          </BodyExternalLink>
          , there is an urgency to address flaring now.
        </ParagraphText>
      </StyledTextSectionWrapper>
    </Wrapper>
  )
}
