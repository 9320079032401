import React from "react"
import { Link } from "gatsby"
import SEO from "../components/global/seo"
import Layout from "../components/global/layout"
import styled from "styled-components"
import QuestionsSection from "../components/global/questions-section"
import Footer from "../components/global/footer"
import Text from "../components/elements/text/text"
import Logo from "../images/header_logo.png"
import Img from "gatsby-image"
import Media from "react-media"

import FirstTextSection from "../components/flaring-emissions-page/first-text-section"
// import SecondTextSection from "../components/flaring-emissions-page/second-text-section"
import ChartSection from "../components/flaring-emissions-page/chart-section"

const TopWrapper = styled.div`
  position: relative;
  z-index: 20;
  @media (max-width: 579px) {
    margin-bottom: -4rem;
  }
`

const FirstSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 0.5rem;
  flex-direction: column;
  text-align: center;
  @media (max-width: 579px) {
    padding-bottom: 0;
  }
  position: relative;
  z-index: 20;
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  position: relative;
  z-index: 20;
`

const StyledLogo = styled.img`
  width: 145px;
`

const EmissionsText = styled(Text)`
  font-size: 3.5rem;
  line-height: 3.5rem;
`

const Line = styled.hr`
  background: ${({ theme }) => theme.fontColorYellow};
  height: 0.35rem;
  margin: 1em 0 1.25em 0;
  width: 8rem;
`

const StyledLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
`

const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 20;
`

const TopoTopWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 10rem;
  z-index: 10;
`

const StyledTopo = styled(Img)`
  opacity: 0.25;
`

const StyledLayout = styled.div`
  position: relative;
  background-color: ${p => p.theme.bgColorDark};
  overflow-x: hidden;
`

const TopoBottomWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
`

export default ({ data }) => {
  return (
    <Media
      queries={{
        small: "(max-width: 375px)",
        normal: "(min-width: 376px)",
      }}
      defaultMatches={{ normal: true }}
    >
      {matches => {
        return (
          <Layout>
            <StyledLayout>
              <SEO title="Flaring Aerial Survey Results" />
              <TopWrapper>
                <TopoTopWrapper>
                  <StyledTopo fixed={data.topoTop.childImageSharp.fixed} />
                </TopoTopWrapper>
                <LogoWrapper>
                  <StyledLink to="/">
                    <StyledLogo src={Logo}></StyledLogo>
                  </StyledLink>
                </LogoWrapper>
                <FirstSectionWrapper>
                  <Text size="large" weight="bold">
                    FLARING
                  </Text>
                  <EmissionsText font="xCompressed" size="xxxl" weight="bold">
                    AERIAL SURVEY RESULTS
                  </EmissionsText>
                  <Line></Line>
                </FirstSectionWrapper>
              </TopWrapper>
              <VideoWrapper>
                <iframe
                  title="Permian Flaring Survey Footage"
                  src="https://player.vimeo.com/video/412762296?embedparameter=value"
                  width="768"
                  height="432"
                  frameborder="0"
                  allowfullscreen
                  style={{ margin: 0 }}
                ></iframe>
              </VideoWrapper>
              <FirstTextSection matches={matches} />
              <ChartSection matches={matches} />
              <TopoBottomWrapper>
                <StyledTopo fixed={data.topoBottom.childImageSharp.fixed} />
              </TopoBottomWrapper>
            </StyledLayout>
            <QuestionsSection />
            <Footer />
          </Layout>
        )
      }}
    </Media>
  )
}

export const query = graphql`
  query {
    topoTop: file(relativePath: { eq: "topographyFlaringTop-562w.png" }) {
      childImageSharp {
        fixed(width: 562, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    topoBottom: file(relativePath: { eq: "topographyFlaringBottom-469w.png" }) {
      childImageSharp {
        fixed(width: 562, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
