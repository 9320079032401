import React from "react"
import styled from "styled-components"
import H2Text from "../../elements/text/h2-text"
import Text from "../../elements/text/text"
import { ParagraphText, TextSectionWrapper } from "../common-elements"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import ButtonPrimary from "../../elements/buttons/button-primary"
import WhereWeStudiedSection from "../where-we-studied-section"
import CulpritsGraphicsSection from "../culprits-graphics-section"

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding-bottom: 6.25rem;
  position: relative;
  z-index: 20;
`

const StyledTextSectionWrapper = styled(TextSectionWrapper)`
  margin: 0 2em;
  text-align: center;
  @media (max-width: 1430px) and (min-width: 1251px) {
    max-width: 545px;
  }

  @media (max-width: 1250px) and (min-width: 1101px) {
    max-width: 400px;
  }
`

const ImageSection = styled.div`
  display: flex;
  width: 100%;
  max-width: 755px;
  @media (max-width: 780px) {
    flex-direction: column;
    align-items: center;
  }
`

const BarChartSection = styled.div`
  width: 100%;
  background-color: ${p => p.theme.bgColorLight};
  padding: 2rem;
  max-width: 755px;
  text-align: center;
`

const ImageSectionLeft = styled.div`
  margin-right: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 780px) {
    margin-right: 0;
    padding: 1rem 2rem;
  }
`

const ImageSectionRight = styled.div`
  flex: 1;
`

const FlareWrapper = styled.div`
  position: relative;
`

const CalloutTextWrapper = styled.div`
  max-width: 350px;
  @media (max-width: 780px) {
    display: none;
  }
`

const BentWrapper = styled.div`
  bottom: -14px;
  position: absolute;
  left: -12px;
`

const StraightWrapper = styled.div`
  top: 10px;
  position: absolute;
  right: -23px;
`
const PieWrapper = styled.div`
  padding: 1rem;
  padding-bottom: 2.375rem;
  display: flex;
  background-color: ${p => p.theme.bgColorLight};
  @media (max-width: 780px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`

const PieImg = styled.div`
  margin-right: 0.5rem;
`

const BWWrapper = styled.div`
  display: flex;
  margin: 1rem 0;
  @media (max-width: 780px) {
    padding: 0 2rem;
  }
`

const BWFlare = styled.div`
  position: relative;
`
const BWTextWrapper = styled.div`
  padding-left: 2rem;
  padding-top: 10px;
`

const StripesHContainer = styled.div``

const EpaTextWrapper = styled.div`
  margin-top: 1rem;
`

const StripesVWrapper = styled.div`
  position: absolute;
  top: -1rem;
  left: -1rem;
  overflow: hidden;
  @media (max-width: 780px) {
    height: 175px;
  }
`

const StyledLink = styled.a`
  margin-top: 2em;
  padding-top: 0;
`

export default ({ matches }) => {
  const {
    flare,
    flareSmall,
    flareBW,
    flareBWSmall,
    barChart,
    barChartSmall,
    pie,
    bent,
    straight,
    stripesH,
    stripesV,
  } = useStaticQuery(
    graphql`
      query {
        flare: file(relativePath: { eq: "flaringPhoto_351w.jpg" }) {
          childImageSharp {
            fixed(width: 351, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        flareSmall: file(relativePath: { eq: "flaringPhoto-252w.jpg" }) {
          childImageSharp {
            fixed(width: 252, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        flareBW: file(relativePath: { eq: "flaringPhotoBW-126w.jpg" }) {
          childImageSharp {
            fixed(width: 126, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        flareBWSmall: file(relativePath: { eq: "flaringPhotoBW-115w.jpg" }) {
          childImageSharp {
            fixed(width: 115, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        barChart: file(relativePath: { eq: "barChart-583w.png" }) {
          childImageSharp {
            fixed(width: 583, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        barChartSmall: file(relativePath: { eq: "barChart-165w.png" }) {
          childImageSharp {
            fixed(width: 165, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        pie: file(relativePath: { eq: "pieChart-58w.png" }) {
          childImageSharp {
            fixed(width: 58, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        bent: file(relativePath: { eq: "calloutBent-33w.png" }) {
          childImageSharp {
            fixed(width: 33, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        straight: file(relativePath: { eq: "calloutStraight-51w.png" }) {
          childImageSharp {
            fixed(width: 51, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        stripesH: file(relativePath: { eq: "stripesHorizontal-352W.png" }) {
          childImageSharp {
            fixed(width: 352, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        stripesV: file(relativePath: { eq: "stripesVertical-58w.png" }) {
          childImageSharp {
            fixed(width: 58, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  const flareSources = [
    flareSmall.childImageSharp.fixed,
    {
      ...flare.childImageSharp.fixed,
      media: `(min-width: 780px)`,
    },
  ]

  const flareBWSources = [
    flareBWSmall.childImageSharp.fixed,
    {
      ...flareBW.childImageSharp.fixed,
      media: `(min-width: 780px)`,
    },
  ]

  const barChartSources = [
    barChartSmall.childImageSharp.fixed,
    {
      ...barChart.childImageSharp.fixed,
      media: `(min-width: 780px)`,
    },
  ]

  return (
    <Wrapper>
      <StyledTextSectionWrapper>
        <H2Text>FLARING STUDY RESULTS</H2Text>
        <ParagraphText>
          Our research indicates flares often malfunction, making flaring one of
          the largest sources of methane emissions in the basin.
        </ParagraphText>
      </StyledTextSectionWrapper>
      <ImageSection>
        <ImageSectionLeft>
          <FlareWrapper>
            <StripesVWrapper>
              <Img fixed={stripesV.childImageSharp.fixed}></Img>
            </StripesVWrapper>
            <Img fixed={flareSources}></Img>
            <BentWrapper>
              <Img fixed={bent.childImageSharp.fixed}></Img>
            </BentWrapper>
          </FlareWrapper>
          <CalloutTextWrapper>
            <Text size="xl" font="compressed" style={{ lineHeight: 0 }}>
              THERE ARE TWO MAIN CULPRITS
            </Text>
          </CalloutTextWrapper>
        </ImageSectionLeft>
        <ImageSectionRight>
          <PieWrapper>
            <PieImg>
              <Img fixed={pie.childImageSharp.fixed}></Img>
            </PieImg>
            <div>
              <Text
                size="medium"
                color="yellow"
                weight="bold"
                font="xCompressed"
              >
                10 PERCENT
              </Text>
              <div>
                <Text size="small">of flares surveyed</Text>
              </div>
              <div>
                <Text size="small">were</Text>{" "}
                <Text color="yellow" size="small" weight="bold">
                  malfunctioning.
                </Text>
              </div>
            </div>
          </PieWrapper>
          <BWWrapper>
            <BWFlare>
              <Img fixed={flareBWSources}></Img>
              <StraightWrapper>
                <Img fixed={straight.childImageSharp.fixed}></Img>
              </StraightWrapper>
            </BWFlare>
            <BWTextWrapper>
              <div>
                <Text size="small">Including</Text>{" "}
                <Text color="yellow" size="small" weight="bold">
                  5%
                </Text>
                <Text size="small"> of</Text>{" "}
              </div>
              <div>
                <Text size="small">flares that were</Text>{" "}
              </div>
              <div>
                <Text color="yellow" size="small" weight="bold">
                  completely unlit.
                </Text>
              </div>
            </BWTextWrapper>
          </BWWrapper>
          <StripesHContainer>
            <Img fixed={stripesH.childImageSharp.fixed}></Img>
          </StripesHContainer>
        </ImageSectionRight>
      </ImageSection>
      <CulpritsGraphicsSection />
      <BarChartSection>
        <div>
          <ParagraphText weight="bold">
            TOTAL METRIC TONS OF METHANE EMISSIONS FROM FLARING
            <br />
            <Text size="small">
              (EDF Assessment based on VIIRS satellite data)
            </Text>
          </ParagraphText>
        </div>
        <Img fixed={barChartSources}></Img>
        <EpaTextWrapper>
          <Text size="small">
            *EPA assumes functioning flares have 98% flaring efficiency rate
          </Text>
        </EpaTextWrapper>
      </BarChartSection>
      <WhereWeStudiedSection />
      <StyledLink
        href={
          matches.normal
            ? "https://data.permianmap.org/pages/flaring"
            : "https://data.permianmap.org/pages/mobile-flaring"
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <ButtonPrimary>EXPLORE THE DATA</ButtonPrimary>
      </StyledLink>
    </Wrapper>
  )
}
