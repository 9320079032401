import React from "react"
import styled from "styled-components"
import Text from "../../elements/text/text"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const Wrapper = styled.div`
  width: 100%;
  max-width: 755px;
  display: flex;
  @media (max-width: 780px) {
    max-width: 450px;
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;
  }
`

const GraphicWrapper = styled.div`
  flex: 1px;
  padding: 1rem 3rem;
`

const Graphic = styled(Img)`
  width: 100%;
  img {
    margin-bottom: 0;
  }
`

const CalloutTextWrapper = styled.div`
  display: none;
  @media (max-width: 780px) {
    display: block;
  }
  text-align: center;
`

export default () => {
  const { combustion, unlit } = useStaticQuery(
    graphql`
      query {
        combustion: file(relativePath: { eq: "combustion-issues.png" }) {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        unlit: file(relativePath: { eq: "unlit-and-venting.png" }) {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <Wrapper>
      <CalloutTextWrapper>
        <Text size="large" font="compressed">
          THERE ARE TWO MAIN CULPRITS
        </Text>
      </CalloutTextWrapper>
      <GraphicWrapper>
        <Graphic fluid={combustion.childImageSharp.fluid}></Graphic>
      </GraphicWrapper>
      <GraphicWrapper style={{ maxWidth: "400px" }}>
        <Graphic fluid={unlit.childImageSharp.fluid}></Graphic>
      </GraphicWrapper>
    </Wrapper>
  )
}
