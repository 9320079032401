import React from "react"
import styled from "styled-components"
import Text from "../../elements/text/text"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const Wrapper = styled.div`
  width: 100%;
  padding: 2rem;
  max-width: 755px;
  text-align: center;
  margin-top: 1.5rem;
  position: relative;
  @media (max-width: 780px) {
    margin-top: 135px;
  }
`
const Row = styled.div`
  display: flex;
  position: relative;
  @media (max-width: 780px) {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }
`

const CalloutText = styled(Text)`
  text-align: left;
  @media (max-width: 780px) {
    text-align: center;
    margin-bottom: 16px;
  }
`

const BottomSection = styled.div`
  padding: 16px 24px;
  padding-bottom: 0;
  text-align: left;
  max-width: 315px;
  @media (max-width: 780px) {
    text-align: center;
    padding-bottom: 16px;
  }
`

const TowerImage = styled.div`
  position: absolute;
  top: -85px;
  right: -48px;
  @media (max-width: 780px) {
    right: initial;
    top: -150px;
  }
`

const StyledTowerImageWrapper = styled.div`
  @media (max-width: 780px) {
    display: none;
  }
`
const StyledTowerImage = styled(Img)`
  position: relative;
  z-index: 10;
  margin: 0;
  width: 200px;
`

const StyledTowerImageSmallWrapper = styled.div`
  display: none;
  @media (max-width: 780px) {
    display: block;
  }
`

const StyledTowerImageSmall = styled(Img)`
  @media (max-width: 780px) {
    height: 150px;
    width: 250px;
  }
`

const StyledStripesContainer = styled.div`
  position: absolute;
  top: -35px;
  right: 16px;
  z-index: 20;
  margin: 0;

  @media (max-width: 780px) {
    left: -17px;
    top: -25px;
    right: initial;
  }
`

const StyledStripes = styled(Img)`
  margin: 0;
  height: 27px;
  width: 54px;
`
export default () => {
  const { texasMap, tower, stripes, towerSmall } = useStaticQuery(
    graphql`
      query {
        texasMap: file(relativePath: { eq: "texas-map.png" }) {
          childImageSharp {
            fixed(width: 228, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        combustion: file(relativePath: { eq: "combustion-issues.png" }) {
          childImageSharp {
            fixed(width: 181, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        unlit: file(relativePath: { eq: "unlit-and-venting.png" }) {
          childImageSharp {
            fixed(width: 163, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        tower: file(relativePath: { eq: "blue-sky-flaring.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        towerSmall: file(relativePath: { eq: "blue-sky-flaring-small.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 512, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        stripes: file(relativePath: { eq: "small-stripes-horizontal.png" }) {
          childImageSharp {
            fixed(width: 27, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        bent: file(relativePath: { eq: "calloutBent-33w.png" }) {
          childImageSharp {
            fixed(width: 33, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  return (
    <Wrapper>
      <Row>
        <Img fixed={texasMap.childImageSharp.fixed}></Img>
        <BottomSection>
          <Text size="large" color="yellow" weight="bold" font="compressed">
            WHERE WE STUDIED
          </Text>
          <div>
            <CalloutText size="small">
              We studied 1,200 flares through aerial surveillance in New Mexico
              and Texas. Throughout 2020 we conducted 4 random surveys of flares
              in various areas, and 3 repeat surveys of flares in one specific
              area.
            </CalloutText>
          </div>
        </BottomSection>
        <TowerImage>
          <StyledTowerImageWrapper>
            <StyledTowerImage
              objectFit="cover"
              alt="One of five sensor towers in the Permian Basin measuring methane 24/7"
              fluid={tower.childImageSharp.fluid}
            />
          </StyledTowerImageWrapper>
          <StyledTowerImageSmallWrapper>
            <StyledTowerImageSmall
              objectFit="cover"
              alt="One of five sensor towers in the Permian Basin measuring methane 24/7"
              fluid={towerSmall.childImageSharp.fluid}
            />
          </StyledTowerImageSmallWrapper>
          <StyledStripesContainer>
            <StyledStripes
              objectFit="cover"
              alt="Yellow stripes element"
              fixed={stripes.childImageSharp.fixed}
            />
          </StyledStripesContainer>
        </TowerImage>
      </Row>
    </Wrapper>
  )
}
